<template>
  <WebIframe :web-url="url" />
</template>

<script>
import WebIframe from '@/components/WebIframe'
import { mapGetters } from 'vuex'
export default {
  components: { WebIframe },
  data() {
    return {
      url: ''
    }
  },
  computed: {
    ...mapGetters([
      'shareUrls'
    ])
  },
  mounted() {
    this.url = this.shareUrls.invite_share_rule
  },
  methods: {
  }
}
</script>

<style>

</style>
